/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    // "aws_project_region": "us-east-1",
    // "aws_appsync_graphqlEndpoint": "https://mwyivwbl7fg5rdhsgvkv6jrp2u.appsync-api.us-east-1.amazonaws.com/graphql",
    // "aws_appsync_region": "us-east-1",
    // "aws_appsync_authenticationType": "API_KEY",
    // "aws_appsync_apiKey": "da2-vih3wyxr5jap7bwbe5zxadnvfa",
    // "aws_cognito_identity_pool_id": "us-east-1:0a39bc70-3562-4dc9-8099-5de884c0b7b4",
    // "aws_cognito_region": "us-east-1",
    // "aws_user_pools_id": "us-east-1_XYKBQWHYP",
    // "aws_user_pools_web_client_id": "uvnuf9g7fh3dn0vpvrsa93hmr",
    // "oauth": {
    //     "domain": "mobile6060603c-6060603c-dev.auth.us-east-1.amazoncognito.com",
    //     "scope": [
    //         "phone",
    //         "email",
    //         "openid",
    //         "profile",
    //         "aws.cognito.signin.user.admin"
    //     ],
    //     "redirectSignIn": "http://localhost:3000/",
    //     "redirectSignOut": "http://localhost:3000/",
    //     "responseType": "code"
    // },
    // "federationTarget": "COGNITO_USER_POOLS",
    // "aws_cognito_username_attributes": [],
    // "aws_cognito_social_providers": [
    //     "GOOGLE"
    // ],
    // "aws_cognito_signup_attributes": [
    //     "EMAIL"
    // ],
    // "aws_cognito_mfa_configuration": "OFF",
    // "aws_cognito_mfa_types": [
    //     "SMS"
    // ],
    // "aws_cognito_password_protection_settings": {
    //     "passwordPolicyMinLength": 8,
    //     "passwordPolicyCharacters": []
    // },
    // "aws_cognito_verification_mechanisms": [
    //     "EMAIL"
    // ]
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://xb3x4ndzxrfd3dv63piqrtf7te.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6ymcplq4a5eihpyigzq7mlde5q",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_cC3q3fBKW",
    "aws_user_pools_web_client_id": "1qi0l2sm7hr82ou1mqsv4tc1ff",
    "oauth": {
        "domain": "rightoneducation.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
